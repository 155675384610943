<template>
  <div class="menu_wrapper_container box-shadow">
    <el-row class="table-top">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <h4 class="heading">Live M2M under: Admin Details</h4>
      </el-col>
    </el-row>
    <Table
      tableKey="closePositions"
      :tableConfig="config"
      :tableData="listData"
      :tableQuery="listQuery"
      :tableActionShowVisibility="true"
      :tableActionVisibility="false"
      :tableActionViewVisibility="true"
      :tableActionViewDetailsVisibility="true"
      :tableActionEditVisibility="false"
      :tableActionDeleteVisibility="false"
      :tableLoadingStatus.sync="loading"
      @pagination="handlePagination()"
      @view="handleViewDetails($event)"
    />
    <br />
    <el-row style="margin-bottom: 0px !important">
      <el-col :xs="24" :lg="24" :xl="24">
        <div class="box-cell">
          <div class="box-single-cell">
            
            <div class="box-cell-content">
              <h1>Buy Turnover</h1>
              <h2>{{ BuyTunrover.mcx }}</h2>
              <h3>Mcx</h3>
              
              
              <h2>{{ BuyTunrover.equity }}</h2>
              <h3>Equity</h3>
            </div>
          </div>
          <div class="box-single-cell">
           
            <div class="box-cell-content">
               <h1>Sell Turnover</h1>
              
              <h2>{{ SellTunrover.mcx }}</h2>
              <h3>Mcx</h3>
              
              <h2>{{ SellTunrover.equity }}</h2>
              <h3>Equity</h3>
            </div>
          </div>
          <div class="box-single-cell">
            
            <div class="box-cell-content">
              <h1>Total Turnover</h1>
              
              <h2>{{ TotalTunrover.mcx }}</h2>
              <h3>Mcx</h3>
              
              <h2>{{ TotalTunrover.equity }}</h2>
              <h3>Equity</h3>
            </div>
          </div>
          <div class="box-single-cell">
            
            <div class="box-cell-content">
              <h1>Active Users</h1>
             
              <h2>{{ ActiveUsers.mcx }}</h2>
               <h3>Mcx</h3>
            
              <h2>{{ ActiveUsers.equity }}</h2>
                <h3>Equity</h3>
            </div>
          </div>
          <div class="box-single-cell">
            
            <div class="box-cell-content">
              <h1>Profit / Loss</h1>
             
              <h2>{{ ProfitLoss.mcx }}</h2>
               <h3>Mcx</h3>
              
              <h2>{{ ProfitLoss.equity }}</h2>
              <h3>Equity</h3>
            </div>
          </div>
          <div class="box-single-cell">
            
            <div class="box-cell-content">
              <h1>Brokerage</h1>
            
              <h2>{{ Brokerage.mcx }}</h2>
                <h3>Mcx</h3>
              
              <h2>{{ Brokerage.equity }}</h2>
              <h3>Equity</h3>
            </div>
          </div>
          <div class="box-single-cell">
           
            <div class="box-cell-content">
               <h1>Active Buy</h1>
            
              <h2>{{ ActiveBuy.mcx }}</h2>
                <h3>Mcx</h3>
            
              <h2>{{ ActiveBuy.equity }}</h2>
                <h3>Equity</h3>
            </div>
          </div>
          <div class="box-single-cell">
           
            <div class="box-cell-content">
               <h1>Active Sell</h1>
             
              <h2>{{ ActiveSell.mcx }}</h2>
               <h3>Mcx</h3>
              
              <h2>{{ ActiveSell.equity }}</h2>
              <h3>Equity</h3>
            </div>
          </div>
          <!-- {{listData}} -->
        </div>
      </el-col>
    </el-row>
  </div>
</template>



<script>
import Table from "@/components/Table/Table.vue";
import { fetchList, fetchBrokerDashboard } from "@/api/dashboard";
import { mapGetters } from "vuex";
// import getRoleSlug from "@/store/app.js";

export default {
  name: "Dashboard",
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      config: [
        {
          label: "User ID",
          prop: "name",
          width: "",
          className: "redFont",
        },
        {
          label: "Active Profit/Loss",
          prop: "active_pl",
          width: "",
          className: "blueFont",
        },
        {
          label: "Active Trades",
          prop: "active_trades",
          width: "",
          className: "blueFont",
        },
        {
          label: "Margin Used",
          prop: "margin",
          width: "",
          className: "blueFont",
        },
      ],
      listData: {
        data: {
          item: [],
        },
      },

      ActiveBuy: {},
      ActiveSell: {},
      ActiveUsers: {},
      Brokerage: {},
      BuyTunrover: {},
      ProfitLoss: {},
      SellTunrover: {},
      TotalTunrover: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.id = this.$route.params.id;
    console.log(this.$route.params.id);
    this.handleViewBrokerDetails();
  },
  methods: {
    handleViewBrokerDetails() {
      this.loading = true;
      fetchBrokerDashboard(this.$route.params.id)
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.id = "";
            this.ActiveBuy = response.data.data.active_buy;
            this.ActiveSell = response.data.data.active_sell;
            this.ActiveUsers = response.data.data.active_users;
            this.Brokerage = response.data.data.brokerage;
            this.BuyTunrover = response.data.data.buy_tunrover;
            this.ProfitLoss = response.data.data.profit_loss;
            this.SellTunrover = response.data.data.sell_tunrover;
            this.TotalTunrover = response.data.data.total_tunrover;
            this.listData.data.item = response.data.data.users_data.users;

            console.log("DSFDFDS   EFSDSF  ", this.listData.data.item )
          } else {
            this.formErrors = this.validationErrors(response.data.message);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleViewDetails(data) {
        return this.$router.push("/super-admin/dashboard/admin/broker-details/" + data.master_id + '/' + data.id);
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getList();
    },
    handleSearch() {
      this.listQuery.page = 1;
      this.getList();
    },

    getList() {
      this.loading = true;
      fetchList(this.listQuery).then((response) => {
        this.ActiveBuy = response.data.data.active_buy;
        this.ActiveSell = response.data.data.active_sell;
        this.ActiveUsers = response.data.data.active_users;
        this.Brokerage = response.data.data.brokerage;
        this.BuyTunrover = response.data.data.buy_tunrover;
        this.ProfitLoss = response.data.data.profit_loss;
        this.SellTunrover = response.data.data.sell_tunrover;
        this.TotalTunrover = response.data.data.total_tunrover;
        this.listData.data.item = response.data.data.users_data.users;
        this.loading = false;
      });
    },
  },
};
</script>
